<template>
  <div class="allorder-box">
    <Header> 确认订单 </Header>
    <!-- 寄卖和购买订单 -->
    <div class="box">
      <div
        v-for="(list, typeid) in text"
        :key="typeid"
        @click="changeOrder(list)"
        :class="add == list.type ? 'allorder' : 'purchase '"
      >
        {{ list.name }}
      </div>
    </div>

    <!-- 寄卖订单----寄卖状态 -->
    <div class="allorder_box" v-if="orderType == 1">
      <div
        v-for="(all, id) in allorder"
        :key="id"
        class="allorder_box"
        @click="consignStatus(all)"
        :class="con == all.id ? 'consign_tow' : 'consign'"
      >
        {{ all.test }}
      </div>
    </div>
    <!-- 购买订单----购买状态 -->
    <div class="purchase_box" v-else-if="orderType == 2">
      <div
        v-for="(all, typeid) in purchasing"
        :key="typeid"
        class="allorder_box"
        @click="buyerStatus(all)"
        :class="buy == all.typeid ? 'consign_tow' : 'consign'"
      >
        {{ all.name }}
      </div>
    </div>

    <!-- 申购订单----购买状态 -->
    <div class="purchase_box" v-else-if="orderType == 3">
      <div
        v-for="(all, typeid) in applyTab"
        :key="typeid"
        class="allorder_box"
        @click="applyStatus(all)"
        :class="apply == all.typeid ? 'consign_tow' : 'consign'"
      >
        {{ all.name }}
      </div>
    </div>

    <div class="being" v-if="!goodsorder.length">
      <img src="../../../static/image/emptyBox.png" alt="" />
      <span>暂无订单</span>
    </div>
    <div class="listbox">
      <div class="list" v-for="(list, id) in goodsorder" :key="id">
        <div @click="details(list)">
          <div class="toptimes">
            <span class="lef_time">{{
              list.updated_at || list.created_at
            }}</span>
            <span class="paystuste" v-if="orderType == 1 && con == 1"
              >寄卖中</span
            >
            <span class="paystuste" v-if="orderType == 3 && apply == 1"
              >申购中</span
            >
            <span
              class="paystuste"
              style="color: #cecece"
              v-if="orderType == 3 && apply === 3"
              >取消申购</span
            >
            <span
              class="paystuste"
              v-if="orderType == 3 && apply === 2 && list.status === 0"
              >分配中</span
            >
          </div>
          <!-- 寄卖订单和购买订单 -->
          <div class="maincont" v-if="orderType !== 3">
            <div class="contleft">
              <img class="conimage" :src="list.listPic" alt="" />
            </div>
            <div class="contright">
              <div class="guige">
                <p class="title">{{ list.name }}</p>
                <p class="price">￥{{ list.pay_price }}</p>
              </div>
              <div class="rightpre">
                <p class="baot">编号:{{ list.goodsNumber }}</p>
                <p class="nums">x1</p>
              </div>
            </div>
          </div>
          <!-- 申购订单 -->
          <div class="maincont" v-else>
            <div class="contleft">
              <img class="conimage" :src="list.listPic" alt="" />
            </div>
            <div class="contright">
              <div class="guige">
                <p class="title">{{ list.name }}</p>
                <p class="price">￥{{ list.applyPrice }}</p>
              </div>
              <div class="rightpre">
                <p class="baot"></p>
                <p class="nums">x{{ list.goods_num }}</p>
              </div>
            </div>
          </div>
          <!-- 寄卖中显示内容 -->
          <div class="allfuk" v-if="orderType == 1 && con == 1">
            共1件，寄卖金额：<span>￥{{ list.pay_price }}</span>
          </div>
          <div class="ispay" v-if="orderType == 1 && con == 1">
            <div @click.stop="consignment(list.id)">取消寄卖</div>
          </div>
          <!-- 代付款显示内容 -->
          <div class="allfuk" v-if="orderType == 2 && buy == 1">
            共1件，需付款：<span>￥{{ list.pay_price }}</span>
          </div>
          <div class="purchase" v-if="orderType == 2 && buy == 1">
            <span>支付时间仅剩: {{ list.income_time | timeFormate }}</span>
            <div @click.stop="pay(list)">
              {{ list.pay_channel == 18 ? '余额支付' : '支付宝支付' }}
            </div>
          </div>
          <!-- 申购订单 -->
          <div class="allfuk allfuk1" v-if="orderType == 3 && apply === 3">
            获得数量：<span style="margin-right: 20px">{{
              list.give_number
            }}</span>
            退回金额：
            <span>¥{{ list.money_return }}</span>
          </div>
          <div
            class="allfuk allfuk1"
            v-if="orderType == 3 && apply === 2 && list.status !== 0"
          >
            获得数量：<span style="margin-right: 20px">{{
              list.give_number
            }}</span>
            退回金额：
            <span>¥{{ list.money_return }}</span>
          </div>
          <div class="ispay" v-if="orderType == 3 && apply == 1">
            <div @click.stop="clearApple(list.id)">取消申购</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 取消寄卖弹框 -->
    <van-overlay :show="aaaa" @click="aaaa = false">
      <div class="wrapper" @click.stop>
        <div class="block">
          <div>提示</div>
          <div>是否确认取消寄卖</div>
          <div class="top_block">
            <div class="btm_left" @click="conceal">取消</div>
            <div class="btm_right" @click="remitting">确认</div>
          </div>
        </div>
      </div>
    </van-overlay>
    <!-- 支付宝支付 -->
    <div class="selectPayType" v-if="showPayBox">
      <div class="selectPayTypeBox"></div>
      <div class="bg">
        <div class="close" @click.stop="showPayBox = false">
          <img src="../../../static/image/close.png" alt="" />
        </div>
        <div class="title">提示</div>
        <div class="content">请在支付宝-账单内完成支付</div>
        <div class="btn" @click="confirmPay">打开支付宝</div>
      </div>
    </div>
    <!-- 是否支付完成 -->
    <div class="selectPayType" v-if="paymentCompleted">
      <div class="selectPayTypeBox"></div>
      <div class="bg">
        <div class="close" @click.stop="paymentCompleted = false">
          <img src="../../../static/image/close.png" alt="" />
        </div>
        <div class="title">提示</div>
        <div class="content">是否完成支付?</div>
        <div class="btnGroup">
          <div class="completed" @click="paymentCompletedCb">已完成</div>
          <div class="unCompleted" @click="paymentCompletedCb">未完成</div>
        </div>
      </div>
    </div>
    <div class="payBox" v-if="false">
      <div class="payBoxBg"></div>
      <div class="payBoxContent">
        <div class="head">
          <div class="close" @click.stop="showPayBox = false">
            <img src="../../../static/image/close.png" alt="" />
          </div>
          <div class="title">选择支付方式</div>
        </div>
        <div class="content">
          <div class="pic">¥{{ payPic }}</div>
          <div class="payType">支付方式</div>

          <van-radio-group v-model="payTypeInfo.active">
            <van-cell-group inset>
              <van-cell
                v-for="item in payTypeInfo.list"
                :key="item.id"
                @click.stop="clickPayType(item)"
              >
                <template #title>
                  <div class="payTypeList">
                    <img :src="item.icon" alt="" />
                    <span class="payTypeName"
                      >{{ item.name }}
                      <i v-if="item.id === 0">({{ balance }})</i>
                    </span>
                  </div>
                </template>
                <template #right-icon>
                  <div v-if="item.id === 2">
                    <img
                      src="../../../static/image/right.png"
                      style="width: 10px; margin-right: 5px"
                      alt=""
                    />
                  </div>
                  <van-radio :name="item.id" v-else />
                </template>
              </van-cell>
            </van-cell-group>
          </van-radio-group>

          <div class="btn" @click="confirmPay">确认支付</div>
        </div>
      </div>
    </div>

    <van-overlay :show="showPwd">
      <div class="wap">
        <div class="wap_affirm">
          <div class="cha" @click="showPwd = false">×</div>
          <div>请输入交易密码</div>
          <div class="forgotPwd" @click="$router.push('/forgotPwd')">
            忘记密码
          </div>
        </div>
        <!-- 密码输入框 -->
        <van-password-input
          :value="value"
          :gutter="10"
          :focused="showKeyboard"
          @focus="showKeyboard = true"
          @input="specifiName($event)"
        />
        <!-- 数字键盘 -->
        <van-number-keyboard
          :show="showKeyboard"
          @input="onInput"
          @delete="onDelete"
          @blur="showKeyboard = true"
        />
      </div>
    </van-overlay>
  </div>
</template>

<script>
import remaining from '../../../static/image/remaining.png';
import zhifubao from '../../../static/image/icon.png';
import bank from '../../../static/image/icon@2x.png';
import { isSafari } from '../../utils/tools';
import { Dialog } from 'vant';
export default {
  name: 'Allorder',
  data() {
    return {
      gong: true,
      jimai: true,
      showPayBox: false,
      paymentCompleted: false,
      being: false,
      all: true,
      ispay: false,
      isComplete: false, // 代付款
      isConsign: false, // 寄卖金额
      purchase: false,
      tiem: true,
      aaaa: false,
      payTypeInfo: {
        list: [
          { id: 0, name: '余额', icon: remaining },
          { id: 1, name: '支付宝', icon: zhifubao },
          // { id: 2, name: '银行卡', icon: bank },
        ],
        active: 0,
      },
      purchasing: [
        { name: '已完成', typeid: 2 },
        { name: '待支付', typeid: 1 },
        { name: ' 已取消', typeid: 3 },
      ],
      applyTab: [
        { name: '申购结果', typeid: 2 },
        { name: '申购中', typeid: 1 },
        { name: ' 取消申购', typeid: 3 },
      ],
      allorder: [
        {
          test: '已完成',
          id: 3,
        },
        {
          test: '寄卖中',
          id: 1,
        },
        {
          test: '已取消',
          id: 2,
        },
      ],
      text: [
        {
          name: '寄卖订单',
          type: 1,
        },
        {
          name: '购买订单',
          type: 2,
        },
        {
          name: '申购订单',
          type: 3,
        },
      ],
      showPwd: false,
      orderType: 1, // 1 寄卖，2 购买
      add: 1,
      none: true,
      active: 1,
      show: false,
      con: 3, // 寄卖订单----状态
      buy: 2, // 购买订单----状态
      apply: 2,
      goodsorder: [],
      nickname: '', //寄卖的id
      quxiao: '', //取消购买
      bbb: '',
      payPic: 0,
      balance: 0,
      value: '', //密码输入框
      showKeyboard: true,
      orderNo: null,
      timer: null,
      id: '',
      isOpenAliPay: false,
    };
  },
  // 页面加载
  mounted() {
    // // if (isSafari()) {
    // document.addEventListener('visibilitychange', this.cb);
    // // }
    // if (this.$route.query.type == 2) {
    //   this.add = 2;
    //   this.orderType = 2;
    // }
    // this.getGoodsList(this.orderType, this.con);
    // // 获取余额
    // this.getBalance();
  },
  activated() {
    // if (isSafari()) {
    document.addEventListener('visibilitychange', this.cb);
    // }
    if (this.$route.query.type == 2) {
      this.add = 2;
      this.orderType = 2;
    }
    this.getGoodsList(this.orderType, this.con);
    // 获取余额
    this.getBalance();
    this.isOpenAliPay = false;
  },
  deactivated() {
    this.clearInte();
    document.removeEventListener('visibilitychange', this.cb);
  },
  filters: {
    timeFormate(val) {
      const m = Math.floor(val / 60);
      if (val <= 0) {
        return '支付超时';
      }
      return `${m}分 ${val - m * 60}秒`;
    },
  },
  beforeDestroy() {
    this.clearInte();
    document.removeEventListener('visibilitychange', this.cb);
  },
  methods: {
    cb() {
      if (document.hidden) {
        // 页面被挂起
        console.log('页面被挂起');
      } else {
        console.log('页面呼出');
        if (this.isOpenAliPay) {
          this.paymentCompleted = true;
          this.isOpenAliPay = false;
        }
        // 页面呼出 支付宝购买后返回，刷新次数接口
        // const query = {
        //   token: localStorage.getItem('token'),
        //   order_no: this.orderNo,
        // };
        // this.$api.loop(query).then((e) => {
        //   if (e.code != 100000) return this.$toast(e.msg);
        //   this.info = JSON.stringify(e.data);
        //   if (e.data.status === 2) {
        //     // this.$router.replace('/Allorder?type=2');
        //     this.$router.push(
        //       `unpaid?id=${this.id}&orderType=${this.orderType}`,
        //     );
        //     return this.$toast('支付成功');
        //   } else {
        //     // this.$router.back();
        //     this.$router.push(
        //       `unpaid?id=${this.id}&orderType=${this.orderType}`,
        //     );
        //     return this.$toast('支付失败');
        //   }
        // });
      }
    },
    paymentCompletedCb() {
      this.paymentCompleted = false;
      let params = {
        token: localStorage.getItem('token'),
        id: this.id,
        type: 2,
      };
      this.$api.detailsCleared(params).then((res) => {
        if (res.code == 100000) {
          if (res.data.status == 2) {
            this.$router.push(`unpaid?id=${this.id}&orderType=2`);
          }
        }
      });
      // this.getGoodsList(this.orderType, this.con);
    },
    setTime() {
      this.timer = setInterval(() => {
        this.goodsorder.forEach((e) => {
          if (--e.income_time <= 0) {
            this.clearInte();
          }
        });
      }, 1000);
    },
    // 获取余额
    getBalance() {
      let params1 = {
        token: localStorage.getItem('token'),
      };
      this.$api.withdrawal(params1).then((res) => {
        if (res.code == 100000) {
          this.balance = res.data.fee;
        }
      });
    },
    // 订单数据列表
    getGoodsList(type) {
      let params = {
        token: localStorage.getItem('token'),
        pageindex: 1,
        pagesize: 1000,
        status: type == 1 ? this.con : type == 2 ? this.buy : this.apply,
        type,
      };
      this.$api.goodsorder(params).then((res) => {
        if (res.code == 100000) {
          this.goodsorder = res.data;
          if (type == 2 && this.buy == 1) {
            this.setTime();
            this.$api.payChannel().then((e) => {
              if (e.code === 0) {
                if ((e.data || {}).pay_channel != 1) {
                  this.goodsorder.forEach((e) => {
                    e.pay_channel = 18;
                  });
                }
              }
            });
          }
          if (this.goodsorder.length == 0) {
            this.all = false;
            this.being = true;
          }
        }
      });
    },
    details(item) {
      this.$router.push({
        path: '/unpaid',
        query: {
          id: this.orderType == 3 ? item.good_order_id : item.id,
          orderType: this.orderType,
        },
      });
    },

    // 取消购买
    Cancel(id) {
      let params = {
        token: localStorage.getItem('token'),
        id: id,
      };
      this.$api.cancelUserOrder(params).then((res) => {
        if (res.code == 0) {
          this.$toast(res.msg);
        }
      });
    },
    // 取消寄卖
    conceal() {
      this.aaaa = false;
    },
    remitting() {
      let params = {
        id: this.nickname,
        token: localStorage.getItem('token'),
      };

      this.$api.anyCancel(params).then((res) => {
        if (res.code == 0) {
          this.aaaa = false;
          this.$toast(res.msg);
          this.getGoodsList(this.orderType, this.con);
          // if (location.href.indexOf("#reloaded") == -1) {
          // location.href = location.href + '#reloaded';
          // location.reload();
          return;
          // }
        }
      });
    },
    consignment(id) {
      this.nickname = id;
      // this.aaaa = true;
      Dialog.confirm({
        title: '提示',
        message: '是否确认取消寄卖？',
      })
        .then(() => {
          // 确认
          let params = {
            id: this.nickname,
            token: localStorage.getItem('token'),
          };

          this.$api.anyCancel(params).then((res) => {
            if (res.code == 0) {
              this.aaaa = false;
              this.$toast(res.msg);
              this.getGoodsList(this.orderType, this.con);
              return;
            } else {
              return this.$toast(res.msg);
            }
          });
        })
        .catch(() => {
          // 取消
        });
    },
    clearApple(id) {
      Dialog.confirm({
        title: '提示',
        message: '是否确认取消申购？',
      })
        .then(() => {
          // 确认
          let params = {
            order_id: id,
            token: localStorage.getItem('token'),
          };

          this.$api.shengouCancle(params).then((res) => {
            this.$toast(res.msg);
            if (res.code == 0) {
              this.getGoodsList(this.orderType, this.con);
              return;
            }
          });
        })
        .catch(() => {
          // 取消
        });
    },
    // 确认订单
    buyerStatus(item) {
      this.clearInte();
      this.goodsorder = [];
      this.buy = item.typeid;
      this.getGoodsList(this.orderType, this.con);
      if (item.typeid == 1) {
        this.isComplete = true;
      } else {
        this.isComplete = false;
      }
    },
    // 确认订单
    applyStatus(item) {
      this.clearInte();
      this.goodsorder = [];
      this.apply = item.typeid;
      this.getGoodsList(this.orderType, this.apply);
    },
    clearInte() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
    // 寄卖和购买订单
    changeOrder(item) {
      this.isConsign = false;
      this.clearInte();
      this.goodsorder = [];
      this.orderType = item.type; // 改变订单
      this.bbb = item.type;
      this.add = item.type;
      this.getGoodsList(this.orderType, this.con);
    },

    // 寄卖
    consignStatus(item) {
      this.clearInte();
      this.goodsorder = [];
      this.con = item.id;
      this.getGoodsList(this.orderType, this.con);
      if (item.id == 1) {
        this.isConsign = true;
      } else {
        this.isConsign = false;
      }
    },
    nana() {
      this.show = false;
      this.none = true;
    },
    lyn() {
      this.show = true;
      this.none = false;
    },
    back() {
      this.$router.push('/user');
    },
    // 付款
    pay(item) {
      this.payPic = item.pay_price;
      this.orderNo = item.order_no;
      this.id = item.id;
      if (item.pay_channel == 18) {
        this.payTypeInfo.active = 0;
        this.showPwd = true;
      } else {
        this.payTypeInfo.active = 1;
        this.showPayBox = true;
      }
    },
    clickPayType(item) {
      if (item.id === 2) {
        return this.$toast('未获取银行卡信息');
      }
    },
    // 确认支付
    confirmPay() {
      this.$api.payChannel().then((e) => {
        if (e.code === 0) {
          if ((e.data || {}).pay_channel != 1) {
            this.goodsorder.forEach((e) => {
              e.pay_channel = 18;
            });
            this.$toast('请使用余额支付');
          } else {
            this.isOpenAliPay = true;
            window.open('alipays://platformapi/startapp?appId=20000003');
          }
        }
      });
      this.showPayBox = false;
      // this.showPayBox = false;
      // this.value = '';
      // if (this.payTypeInfo.active === 1) {
      //   // 支付宝
      //   let params = {
      //     token: localStorage.getItem('token'),
      //     pay_channel: 'alipay_wap', // 余额支付
      //     id: this.id,
      //   };
      //   this.$api.pendingPayment(params).then((res) => {
      //     if (res.code == 100000) {
      //       this.orderNo = res.data.order_no;
      //       localStorage.setItem('pay', 1);
      //       if (isSafari()) {
      //         window.location.href = res.data.expend.pay_info;
      //       } else {
      //         window.location.href = res.data.expend.pay_info;
      //         // this.$router.push({
      //         //   path: '/Pay',
      //         //   query: {
      //         //     payUrl: res.data.expend.pay_info,
      //         //     orderNo: res.data.order_no,
      //         //     orderType: this.orderType,
      //         //     id: this.id,
      //         //   },
      //         // });
      //       }
      //       // window.location.href = res.data.expend.pay_info;
      //     } else {
      //       this.$toast(res.msg);
      //     }
      //   });
      // } else {
      //   this.showPwd = true;
      // }
    },

    onInput(key) {
      if (this.value.length > 5) return;
      this.value = (this.value + key).slice(0, 6);
      if (this.value.length === 6) {
        // 余额
        if (this.payTypeInfo.active === 0) {
          let params = {
            token: localStorage.getItem('token'),
            pay_channel: 'balance', // 余额支付
            id: this.id,
            payPassword: this.value,
          };
          this.$api.pendingPayment(params).then((res) => {
            this.$toast(res.msg);
            if (res.code == 100000) {
              this.$router.push(`unpaid?id=${this.id}&orderType=2`);
              // this.$router.push('/user');
            } else {
              this.value = '';
            }
          });
        }
      }
    },
    onDelete() {
      this.value = this.value.slice(0, this.value.length - 1);
    },
  },
};
</script>

<style lang="less" scoped>
.being {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  color: #999;
  font-size: 3.47vw;
  img {
    width: 32.8vw;
    height: 34.13vw;
  }
}
.list {
  background: #fff;
  padding: 10px;
  margin: 10px;
  border-radius: 3px;
  .toptimes {
    display: flex;
    justify-content: space-between;
    .lef_time {
      color: #909196;
      font-size: 12px;
    }
    .paystuste {
      color: #fab878;
      font-size: 12px;
    }
  }
  .maincont {
    display: flex;
    margin-top: 10px;
  }
  .contleft {
    width: 80px;
    height: 80px;
    .conimage {
      height: 80px;
      width: 80px;
      border-radius: 3px;
    }
  }
  .contright {
    margin-left: 15px;
    margin-top: 10px;
    width: 72%;
    .guige,
    .rightpre {
      display: flex;
      justify-content: space-between;
    }
    .rightpre {
      text-align: right;
    }
    .title {
      font-size: 15px;
      font-weight: bold;
      line-height: 25px;
    }
    .baot {
      font-size: 12px;
      line-height: 25px;
      color: #929498;
    }
    .price {
      font-size: 18px;
    }
    .nums {
      font-size: 12px;
      color: #929498;
      line-height: 25px;
    }
  }
  .allfuk {
    font-size: 12px;
    color: #909196;
    text-align: right;
    margin-top: 10px;
    span {
      color: #e76810;
    }
  }
  .allfuk1 {
    span {
      color: #000000;
    }
  }
  .ispay {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10px;
    span {
      color: #fab878;
      font-size: 12px;
    }
    div {
      width: 77px;
      height: 23px;
      background: #ffffff;
      color: #fab878;
      font-size: 12px;
      border-radius: 3px;
      border: #fab878 1px solid;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .purchase {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    span {
      color: #fab878;
      font-size: 12px;
    }
    .quxiao {
      background-color: #ffffff;
      color: #999999;
      margin-right: 20px;
    }
    div {
      width: 77px;
      height: 23px;
      background: #e76810;
      border-radius: 6px;
      font-size: 12px;
      border-radius: 3px;
      border: none;
      color: #ffffff;
      border: #e76810 1px solid;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.allorder_box {
  display: flex;
  justify-content: space-around;

  .consign {
    color: #999999;
  }
  .consign_tow {
    color: black;
    font-weight: 600;
  }
  font-size: 13px;
  height: 58px;
  line-height: 58px;
}
.purchase_box {
  display: flex;
  justify-content: space-around;
  color: #999999;
  font-size: 13px;
  height: 58px;
  line-height: 58px;
  .consign {
    color: #999999;
  }
  .consign_tow {
    color: black;
    font-weight: 600;
  }
}
.wrapper {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0 auto;
  .block {
    width: 100%;
    border-radius: 5px;
    text-align: center;
    font-size: 16px;
    height: 120px;
    background-color: #fff;
    line-height: 40px;
    .top_block {
      width: 100%;
      border-top: 2px #eeeeee solid;
      display: flex;
      font-size: 18px;
      color: rgba(28, 28, 145, 0.664);
      // justify-content: space-around;
      .btm_right {
        border-left: 2px #eeeeee solid;
        width: 50%;
        text-align: center;
      }
      .btm_left {
        width: 50%;
        text-align: center;
      }
    }
  }
}
.box {
  background-color: #ffffff;
  display: flex;
  justify-content: space-around;
  font-size: 15px;
  font-weight: 500;
  color: #000000;
  line-height: 20px;
  line-height: 42px;
  height: 42px;
  .allorder {
    border-bottom: 3px solid #000000;
    padding-left: 30px;
    padding-right: 30px;
  }

  .purchase {
    color: #999999;
    padding-left: 30px;
    padding-right: 30px;
  }
}
.allorder-box {
  background: #f3f4f8;
  min-height: 100vh;
}
.payBox {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  .payBoxBg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
  }
  .payBoxContent {
    background: #fff;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    overflow: hidden;
    .head {
      padding: 10px;
      position: relative;
      .title {
        font-size: 18px;
        color: #333;
        text-align: center;
      }
      .close {
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        width: 15px;
        img {
          width: 100%;
        }
      }
    }
    .content {
      .pic {
        font-size: 20px;
        font-weight: bold;
        color: #e76810;
        text-align: center;
        margin: 20px 0;
      }
      .payType {
        font-size: 14px;
        font-weight: bold;
        padding: 0 20px;
      }
    }
  }
}
/deep/.van-cell-group--inset {
  margin: 0;
}
/deep/.van-cell {
  padding: 10px 20px;
}
.payTypeList {
  display: flex;
  align-items: center;
  img {
    width: 18px;
    height: 18px;
    vertical-align: middle;
    margin-right: 5px;
  }
}
.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #fff;
  margin: 20px auto;
  border-radius: 3px;
  width: 180px;
  height: 38px;
  background: #0754d3;
  border-radius: 5px;
  font-size: 14px;
}
/deep/.van-password-input__item--focus {
  border: 2px #666666 solid;
  border-radius: 5px;
}
/deep/.van-password-input__item {
  //   background-color: #999999;
  border-radius: 5px;
  border: 2px #999999 solid;
  box-sizing: border-box;
}
/deep/.van-password-input {
  margin-bottom: 20px;
}
/deep/.van-number-keyboard {
  position: relative;
}
.wap {
  background-color: #ffffff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  padding-top: 10px;
}
// .cha {
//   color: #666666;
//   font-size: 30px;
//   margin-left: 20px;
// }
// .wap_affirm {
//   font-size: 17px;
//   // margin-top: 240px;
//   display: flex;
//   font-size: 20px;
//   width: 100%;
//   line-height: 60px;
//   margin-bottom: 20px;
//   .cha {
//     font-size: 40px;
//     color: #999999;
//     margin-right: 85px;
//     margin-left: 10px;
//   }
// }
.wap_affirm {
  font-size: 17px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  div {
    flex: 2;
    text-align: center;
    font-size: 16px;
  }
  .cha {
    flex: 1;
    color: #666666;
    text-align: left;
    font-size: 30px;
  }
  .forgotPwd {
    flex: 1;
    text-align: right;
    font-size: 14px;
    color: rgb(rgb(7 84 211));
  }
}
.selectPayType {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  .selectPayTypeBox {
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }
  .bg {
    width: 80%;
    height: 200px;
    background: #ffffff;
    border-radius: 5px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .close {
      position: absolute;
      right: 10px;
      top: 0;
      width: 15px;
      img {
        width: 100%;
      }
    }
    .title {
      font-size: 20px;
      font-weight: bold;
      color: #000000;
      text-align: center;
      margin-top: 25px;
    }
    .content {
      font-size: 16px;
      color: #666666;
      margin: 20px 0 35px;
      text-align: center;
    }
    .btn {
      width: 60%;
      height: 35px;
      margin: 0 auto;
      background: #0754d3;
      border-radius: 3px;
    }
    .btnGroup {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 30px;
      div {
        background: #0754d3;
        border-radius: 3px;
        font-size: 14px;
        color: #fff;
        padding: 5px 15px;
        border: 1px solid transparent;
        &.unCompleted {
          border: 1px solid #0754d3;
          background: transparent;
          color: #0754d3;
        }
      }
    }
  }
}
</style>
